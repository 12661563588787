
import { defineComponent, ref } from "vue";
import TopBar from "@/components/TopBar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";
import ChatBox from "@/components/productsBox.vue";
import BackTop from "@/components/BackTop.vue";
import CookieBox from "@/components/CookieBox.vue";

export default defineComponent({
  name: "Company",
  components: {
    TopBar,
    Breadcrumb,
    Footer,
    ChatBox,
    BackTop,
    CookieBox,
  },
  metaInfo: {
    title: "B2B Marketplace - Chemicals & Steel - OTIMES",
    meta: [
      {
        name: "description",
        content:
          "OTIMES was established in Shenzhen, China, in 2016. We provide information, intelligent logistics, price index, open source technology, online and offline trading and other supply chain services to more than 3000 small and medium-sized enterprises worldwide",
      },
      {
        name: "keywords",
        content:
          "OTIMES,PLA,PC,POM,PA,ABS,PP,Engineering Plastics,PLASTIC MATERIALS,STEEL,Cold Rolled Steel,Silicon Steel",
      },
    ],
  },
  setup() {
    return {};
  },
});
